import React from "react";
import { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import AddDialog from "../components/AddDialog";
import Datatable from "../components/Datatable";
import MonthlySummary from "../components/MonthlySummary";
import { Link } from "react-router-dom";

export default function Main({ verification }) {
  const [bills, setBills] = useState([]);
  const [thisMonth, setThisMonth] = useState([]);
  const [monthlyData, setMonthlyData] = useState([]);
  const [recentData, setRecentData] = useState([]);
  const [updateBill, setUpdateBill] = useState("");
  const [open, setOpen] = React.useState(true);

  useEffect(() => {
    setThisMonth(parseInt(new Date().getMonth()) + 1);
  }, []);

  useEffect(() => {
    const getBills = async () => {
      // const res = await axios.get("/api/bills");
      const res = await fetch(
        "https://minewhouseholdserver.duckdns.org/api/bills"
      );
      var bills = await res.json();
      setBills(bills);

      var thisMonthData = bills;
      thisMonthData = thisMonthData.filter(
        (e) => parseInt(e.bill.date.split("-")[1]) === thisMonth
      );
      setMonthlyData(thisMonthData);
      thisMonthData.sort((a, b) => {
        return (
          b.bill.date.replaceAll("-", "") - a.bill.date.replaceAll("-", "")
        );
      });

      var count = 0;
      var topData = [];
      thisMonthData.forEach((e) => {
        if (count < 5) {
          topData.push(e);
          count++;
        }
      });
      console.log(topData);
      setRecentData(topData);
    };

    getBills();
  }, [open, updateBill]);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <main className="container">
      <div
        className="title-bar"
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          padding: "0 20px",
          margin: 0,
          backgroundColor: "#dedede",
        }}
      >
        <h1>Canada Team Householder</h1>
        <Button
          variant="outlined"
          style={{ height: 40, borderRadius: 20, fontSize: 20 }}
          onClick={handleClickOpen}
        >
          +
        </Button>
      </div>
      <section className="inner-container">
        <div
          className="section-title"
          style={{
            paddingBottom: 20,
            paddingTop: 40,
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <div
            style={{
              fontSize: 30,
              fontWeight: 500,
            }}
          >
            Recent Bills
          </div>
          <Link to="Index">
            <Button
              variant="tonel"
              style={{ height: 40, borderRadius: 10, fontSize: 15 }}
            >
              View All
            </Button>
          </Link>
        </div>
        {bills && <Datatable data={recentData} setUpdateBill={setUpdateBill} />}
        <div
          className="section-title"
          style={{
            fontSize: 30,
            fontWeight: 500,
            paddingBottom: 20,
            paddingTop: 40,
          }}
        >
          Montly Summary
        </div>
        {bills && <MonthlySummary data={monthlyData} />}
      </section>
      <AddDialog open={open} handleClose={handleClose} />
    </main>
  );
}
