import * as React from "react";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";

export default function BillCategory({ category, setCategory }) {
  const handleChange = (event) => {
    setCategory(event.target.value);
  };

  return (
    <FormControl variant="standard" sx={{ width: "100%" }}>
      <InputLabel id="demo-simple-select-standard-label">Category</InputLabel>
      <Select
        labelId="demo-simple-select-standard-label"
        id="demo-simple-select-standard"
        value={category}
        onChange={handleChange}
      >
        <MenuItem value="mirue">Mirue</MenuItem>
        <MenuItem value="shopping">Shopping</MenuItem>
        <MenuItem value="food">Food</MenuItem>
        <MenuItem value="house">House</MenuItem>
        <MenuItem value="traffic">Traffic</MenuItem>
        <MenuItem value="mobile">Mobile</MenuItem>
        <MenuItem value="personal">Personal</MenuItem>
        <MenuItem value="culture">Culture</MenuItem>
        <MenuItem value="hospital">Hospital</MenuItem>
        <MenuItem value="gift">Gift</MenuItem>
        <MenuItem value="beauty">Beauty</MenuItem>
        <MenuItem value="etc">Etc</MenuItem>
      </Select>
    </FormControl>
  );
}
