import React from "react";
import axios from "axios";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Button from "@mui/material/Button";
import DeleteIcon from "@mui/icons-material/Delete";

export default function Datatable({ data, setUpdateBill }) {
  async function handleDelete(id) {
    const res = await axios.delete(`/api/bills/${id}`);
    console.log("id", id);
    // const res = await fetch(`/api/bills/${id}`, {
    //   method: "DELETE",
    // });
    console.log(res);
    const newBills = await res.json();
    setUpdateBill(newBills);
  }

  const setPaymentTypeTable = (value) => {
    switch (value) {
      case "ktc":
        return "Katelin's Creadit";
      case "ktd":
        return "Katelin's Debit";
      case "jtd":
        return "Jaxtin's Creadit";
      case "mnd":
        return "Minew's Creadit";
      default:
        return "";
    }
  };
  return (
    <div className="bills-table">
      <TableContainer component={Paper} style={{ borderRadius: 15 }}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow style={{ backgroundColor: "#dede99" }}>
              <TableCell>User</TableCell>
              <TableCell>+/-</TableCell>
              <TableCell>Date</TableCell>
              <TableCell>Amount(CAD)</TableCell>
              <TableCell>Payment Type</TableCell>
              <TableCell>Category</TableCell>
              <TableCell>Description</TableCell>
              <TableCell>Delete</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map((item) => (
              <TableRow
                key={item._id}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell className="table-item">{item.bill.user}</TableCell>
                <TableCell className="table-item">{item.bill.inout}</TableCell>
                <TableCell className="table-item" style={{ minWidth: 70 }}>
                  {item.bill.date}
                </TableCell>
                <TableCell className="table-item">
                  ${item.bill.amount}
                </TableCell>
                <TableCell className="table-item" style={{ minWidth: 150 }}>
                  {setPaymentTypeTable(item.bill.paymentType)}
                </TableCell>
                <TableCell className="table-item">
                  {item.bill.category}
                </TableCell>
                <TableCell className="table-item">
                  {item.bill.description}
                </TableCell>
                <TableCell>
                  <Button
                    variant="outlined"
                    style={{ border: "none", fontSize: 20 }}
                    onClick={() => {
                      handleDelete(item._id);
                    }}
                  >
                    <DeleteIcon style={{ color: "#ff5454" }} />
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
}
