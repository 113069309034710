import * as React from "react";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import Checkbox from "@mui/material/Checkbox";

export default function BillCheck({ validation, setValidation }) {
  const handleChange = () => {
    setValidation(true);
  };

  return (
    <FormControl>
      <FormControlLabel
        required
        control={<Checkbox />}
        value={validation}
        label="Did you upload the receipt on Google Drive as Photo?"
        onChange={handleChange}
      />
    </FormControl>
  );
}
