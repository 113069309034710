import * as React from "react";
import TextField from "@mui/material/TextField";
import { FormControl } from "@mui/material";

export default function BillDescription({ description, setDescription }) {
  const handleChange = (e) => {
    setDescription(e.target.value);
  };

  return (
    <FormControl fullWidth sm={{ width: "100%" }}>
      <TextField
        id="standard-textarea"
        label="Description"
        placeholder="Description"
        style={{ width: "100%" }}
        multiline
        minRows={3}
        variant="standard"
        onChange={handleChange}
      />
    </FormControl>
  );
}
