import * as React from "react";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";

export default function BillPaymentType({ paymentType, setPaymentType }) {
  const handleChange = (event) => {
    setPaymentType(event.target.value);
  };

  return (
    <FormControl variant="standard" sx={{ width: "100%" }}>
      <InputLabel id="demo-simple-select-standard-label">
        Payment Type
      </InputLabel>
      <Select
        labelId="demo-simple-select-standard-label"
        id="demo-simple-select-standard"
        value={paymentType}
        onChange={handleChange}
      >
        <MenuItem value="ktc">Katelin's Creadit</MenuItem>
        <MenuItem value="ktd">Katelin's Debit</MenuItem>
        <MenuItem value="jtd">Jaxtyn's Debit</MenuItem>
        <MenuItem value="mnd">MiNew's Debit</MenuItem>
      </Select>
    </FormControl>
  );
}
