import { LocalizationProvider } from "@mui/x-date-pickers";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { FormControl } from "@mui/material";
import dayjs from "dayjs";

export default function BillDatePicker({ date, setDate }) {
  const handleChange = (e) => {
    setDate(e.$d.toISOString().slice(0, 10));
  };
  return (
    <FormControl fullWidth variant="standard">
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DatePicker
          defaultValue={dayjs(date)}
          views={["year", "month", "day"]}
          onChange={handleChange}
        />
      </LocalizationProvider>
    </FormControl>
  );
}
