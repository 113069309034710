import * as React from "react";
import axios from "axios";
import Button from "@mui/material/Button";
// import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
// import DialogContentText from "@mui/material/DialogContentText";
import Slide from "@mui/material/Slide";
import { Grid } from "@mui/material";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";

import SelectUserRadio from "./forms/SelectUserRadio";
import BillInOut from "./forms/BillInOut";
import BillDatePicker from "./forms/BillDatePicker";
import BillAmount from "./forms/BillAmount";
import BillPaymentType from "./forms/BillPaymentType";
import BillCategory from "./forms/BillCategory";
import BillDescription from "./forms/BillDescription";
import BillCheck from "./forms/BillCheck";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function AddDialog({ open, handleClose }) {
  const today = new Date().toISOString().slice(0, 10);
  console.log(today);

  const [user, setUser] = React.useState("katelin");
  const [inOut, setInOut] = React.useState("out");
  const [date, setDate] = React.useState(today);
  const [amount, setAmount] = React.useState("");
  const [paymentType, setPaymentType] = React.useState("ktc");
  const [category, setCategory] = React.useState("shopping");
  const [description, setDescription] = React.useState("");
  const [validation, setValidation] = React.useState(false);

  const [alert, setAlert] = React.useState(false);
  const handleAlertClose = () => {
    setAlert(false);
  };

  const sendToBack = async (bill) => {
    // const res = await axios.post("/api/bills", JSON.stringify({ bill }));
    const res = await fetch(
      "https://minewhouseholdserver.duckdns.org/api/bills",
      {
        method: "POST",
        body: JSON.stringify({ bill }),
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    const newBills = await res.json();
    console.log(newBills);
    // setContent("");
    // setBills([...bills, newBills]);
    handleClose();
  };

  const checkValidation = (bill, validation) => {
    var unvalidation = true;
    Object.keys(bill).forEach(function (key) {
      if (bill[key] === "") {
        unvalidation = false;
      }
    });

    if (unvalidation && validation) {
      sendToBack(bill);
    } else {
      setAlert(true);
      return;
    }
  };

  const createNewBills = async (e) => {
    const bill = {
      user: user,
      inout: inOut,
      date: date,
      amount: amount,
      paymentType: paymentType,
      category: category,
      description: description,
    };

    checkValidation(bill, validation);

    e.preventDefault();
  };

  return (
    <React.Fragment>
      <Dialog
        open={open}
        onClose={handleClose}
        fullScreen
        TransitionComponent={Transition}
      >
        <AppBar sx={{ position: "relative" }}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              Add a new Bill
            </Typography>
            <Button autoFocus color="inherit" onClick={createNewBills}>
              save
            </Button>
          </Toolbar>
        </AppBar>
        <DialogContent>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <SelectUserRadio user={user} setUser={setUser} />
            </Grid>
            <Grid item xs={12}>
              <BillInOut inOut={inOut} setInOut={setInOut} />
            </Grid>
            <Grid item xs={12}>
              <BillDatePicker date={date} setDate={setDate} />
            </Grid>
            <Grid item xs={6}>
              <BillAmount amount={amount} setAmount={setAmount} />
            </Grid>
            <Grid item xs={6}>
              <BillPaymentType
                paymentType={paymentType}
                setPaymentType={setPaymentType}
              />
            </Grid>
            <Grid item xs={12}>
              <BillCategory category={category} setCategory={setCategory} />
            </Grid>
            <Grid item xs={12}>
              <BillDescription
                description={description}
                setDescription={setDescription}
              />
            </Grid>
            <Grid item xs={12}>
              <BillCheck
                vaildation={validation}
                setValidation={setValidation}
              />
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
      <Dialog open={alert}>
        <div
          style={{
            padding: "0 20px 20px",
            backgroundColor: "rgba(255, 55,55,0.8)",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <h3 style={{ color: "white" }}>Check form again</h3>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleAlertClose}
              aria-label="close"
            >
              <CloseIcon style={{ color: "white" }} />
            </IconButton>
          </div>
          <p style={{ color: "white" }}>
            You might missing contents or checkbox
          </p>
        </div>
      </Dialog>
    </React.Fragment>
  );
}
