import React, { useState } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Login from "./pages/Login";
import Main from "./pages/Main";
import Index from "./pages/Index";

export default function App() {
  const [verification, setVerification] = useState(true);

  return (
    <main className="container">
      <BrowserRouter>
        <Routes>
          <Route path="/">
            {/* <Route element={<Login setVerification={setVerification} />} /> */}
            <Route index element={verification && <Main />} />
            <Route path="index" element={verification && <Index />} />
          </Route>
        </Routes>
      </BrowserRouter>
    </main>
  );
}
