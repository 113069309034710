import React, { useEffect, useState } from "react";

export default function CategoryBar({ data }) {
  const [mirue, setmirue] = useState(0);
  const [shopping, setshopping] = useState(0);
  const [food, setfood] = useState(0);
  const [house, sethouse] = useState(0);
  const [traffic, settraffic] = useState(0);
  const [mobile, setmobile] = useState(0);
  const [personal, setpersonal] = useState(0);
  const [culture, setculture] = useState(0);
  const [hospital, sethospital] = useState(0);
  const [gift, setgift] = useState(0);
  const [beauty, setbeauty] = useState(0);
  const [etc, setetc] = useState(0);

  useEffect(() => {
    const setCategoryBar = () => {
      setmirue(data.filter((e) => e.bill.category === "mirue").length);
      setshopping(data.filter((e) => e.bill.category === "shopping").length);
      setfood(data.filter((e) => e.bill.category === "food").length);
      sethouse(data.filter((e) => e.bill.category === "house").length);
      settraffic(data.filter((e) => e.bill.category === "traffic").length);
      setmobile(data.filter((e) => e.bill.category === "mobile").length);
      setpersonal(data.filter((e) => e.bill.category === "personal").length);
      setculture(data.filter((e) => e.bill.category === "culture").length);
      sethospital(data.filter((e) => e.bill.category === "hospital").length);
      setgift(data.filter((e) => e.bill.category === "gift").length);
      setbeauty(data.filter((e) => e.bill.category === "beauty").length);
      setetc(data.filter((e) => e.bill.category === "etc").length);
    };

    setCategoryBar();
  }, [
    data,
    mirue,
    shopping,
    food,
    house,
    traffic,
    mobile,
    personal,
    culture,
    hospital,
    gift,
    beauty,
    etc,
  ]);

  return (
    <>
      <div className="category-bar">
        {mirue !== 0 && (
          <div
            className="category-item mirue"
            style={{ width: `${(mirue / data.length) * 100}%` }}
          >
            mirue
          </div>
        )}
        {shopping !== 0 && (
          <div
            className="category-item shopping"
            style={{ width: `${(shopping / data.length) * 100}%` }}
          >
            shopping
          </div>
        )}
        {food !== 0 && (
          <div
            className="category-item food"
            style={{ width: `${(food / data.length) * 100}%` }}
          >
            food
          </div>
        )}
        {house !== 0 && (
          <div
            className="category-item house"
            style={{ width: `${(house / data.length) * 100}%` }}
          >
            house
          </div>
        )}
        {traffic !== 0 && (
          <div
            className="category-item traffic"
            style={{ width: `${(traffic / data.length) * 100}%` }}
          >
            traffic
          </div>
        )}
        {mobile !== 0 && (
          <div
            className="category-item mobile"
            style={{ width: `${(mobile / data.length) * 100}%` }}
          >
            mobile
          </div>
        )}
        {personal !== 0 && (
          <div
            className="category-item personal"
            style={{ width: `${(personal / data.length) * 100}%` }}
          >
            personal
          </div>
        )}
        {culture !== 0 && (
          <div
            className="category-item culture"
            style={{ width: `${(culture / data.length) * 100}%` }}
          >
            culture
          </div>
        )}
        {hospital !== 0 && (
          <div
            className="category-item hospital"
            style={{ width: `${(hospital / data.length) * 100}%` }}
          >
            hospital
          </div>
        )}
        {gift !== 0 && (
          <div
            className="category-item gift"
            style={{ width: `${(gift / data.length) * 100}%` }}
          >
            gift
          </div>
        )}
        {beauty !== 0 && (
          <div
            className="category-item beauty"
            style={{ width: `${(beauty / data.length) * 100}%` }}
          >
            beauty
          </div>
        )}
        {etc !== 0 && (
          <div
            className="category-item etc"
            style={{ width: `${(etc / data.length) * 100}%` }}
          >
            etc
          </div>
        )}
      </div>
      <div className="category-description-wrap">
        <div>
          <p>mirue</p>
          <h3
            className="mirue"
            style={{
              backgroundColor: "transparent",
              color: "yellow",
              textShadow: "#888 1px 1px",
            }}
          >
            {Math.round((mirue / data.length) * 10000) / 100.0}%
          </h3>
        </div>
        <div>
          <p>shopping</p>
          <h3
            className="shopping"
            style={{
              backgroundColor: "transparent",
              color: "red",
              textShadow: "#888 1px 1px",
            }}
          >
            {Math.round((shopping / data.length) * 10000) / 100.0}%
          </h3>
        </div>
        <div>
          <p>food</p>
          <h3
            className="food"
            style={{
              backgroundColor: "transparent",
              color: "brown",
              textShadow: "#888 1px 1px",
            }}
          >
            {Math.round((food / data.length) * 10000) / 100.0}%
          </h3>
        </div>
        <div>
          <p>house</p>
          <h3
            className="house"
            style={{
              backgroundColor: "transparent",
              color: "aquamarine",
              textShadow: "#888 1px 1px",
            }}
          >
            {Math.round((house / data.length) * 10000) / 100.0}%
          </h3>
        </div>
        <div>
          <p>traffic</p>
          <h3
            className="traffic"
            style={{
              backgroundColor: "transparent",
              color: "aqua",
              textShadow: "#888 1px 1px",
            }}
          >
            {Math.round((traffic / data.length) * 10000) / 100.0}%
          </h3>
        </div>
        <div>
          <p>mobile</p>
          <h3
            className="mobile"
            style={{
              backgroundColor: "transparent",
              color: "blue",
              textShadow: "#888 1px 1px",
            }}
          >
            {Math.round((mobile / data.length) * 10000) / 100.0}%
          </h3>
        </div>
        <div>
          <p>personal</p>
          <h3
            className="personal"
            style={{
              backgroundColor: "transparent",
              color: "violet",
              textShadow: "#888 1px 1px",
            }}
          >
            {Math.round((personal / data.length) * 10000) / 100.0}%
          </h3>
        </div>
        <div>
          <p>culture</p>
          <h3
            className="culture"
            style={{
              backgroundColor: "transparent",
              color: "green",
              textShadow: "#888 1px 1px",
            }}
          >
            {Math.round((culture / data.length) * 10000) / 100.0}%
          </h3>
        </div>
        <div>
          <p>hospital</p>
          <h3
            className="hospital"
            style={{
              backgroundColor: "transparent",
              color: "gray",
              textShadow: "#888 1px 1px",
            }}
          >
            {Math.round((hospital / data.length) * 10000) / 100.0}%
          </h3>
        </div>
        <div>
          <p>gift</p>
          <h3
            className="gift"
            style={{
              backgroundColor: "transparent",
              color: "skyblue",
              textShadow: "#888 1px 1px",
            }}
          >
            {Math.round((gift / data.length) * 10000) / 100.0}%
          </h3>
        </div>
        <div>
          <p>beauty</p>
          <h3
            className="beauty"
            style={{
              backgroundColor: "transparent",
              color: "blanchedalmond",
              textShadow: "#888 1px 1px",
            }}
          >
            {Math.round((beauty / data.length) * 10000) / 100.0}%
          </h3>
        </div>
        <div>
          <p>etc</p>
          <h3
            className="etc"
            style={{
              backgroundColor: "transparent",
              color: "black",
              textShadow: "#888 1px 1px",
            }}
          >
            {Math.round((etc / data.length) * 10000) / 100.0}%
          </h3>
        </div>
      </div>
    </>
  );
}
