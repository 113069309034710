import * as React from "react";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import Input from "@mui/material/Input";
import InputAdornment from "@mui/material/InputAdornment";

export default function BillAmount({ amount, setAmount }) {
  const handleChange = (e) => {
    setAmount(e.target.value);
  };
  return (
    <FormControl fullWidth sx={{ width: "100%" }} variant="standard">
      <InputLabel htmlFor="standard-adornment-amount">Amount</InputLabel>
      <Input
        id="standard-adornment-amount"
        default
        defaultValue={amount}
        onChange={handleChange}
        startAdornment={<InputAdornment position="start">CAD</InputAdornment>}
      />
    </FormControl>
  );
}
