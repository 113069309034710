import * as React from "react";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";

export default function BillInOut({ inOut, setInOut }) {
  const handleChange = (event) => {
    setInOut(event.target.value);
  };

  return (
    <FormControl>
      <RadioGroup
        row
        aria-labelledby="demo-row-radio-buttons-group-label"
        name="row-radio-buttons-group"
        value={inOut}
        onChange={handleChange}
      >
        <FormControlLabel value="in" control={<Radio />} label="Income" />
        <FormControlLabel value="out" control={<Radio />} label="Outcome" />
      </RadioGroup>
    </FormControl>
  );
}
