import React from "react";
import { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import AddDialog from "../components/AddDialog";
import ButtonGroup from "@mui/material/ButtonGroup";
import Datatable from "../components/Datatable";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import { Link } from "react-router-dom";

export default function Index() {
  const [bills, setBills] = useState("");
  const [updateBill, setUpdateBill] = useState("");
  const [open, setOpen] = React.useState(false);
  const [active, setActive] = React.useState("All");
  const [dateRange, setDateRange] = React.useState("All");

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleUserClicked = (activated) => {
    setActive(activated);
  };

  const handleDateRangeClicked = (activated) => {
    setDateRange(activated);
  };

  useEffect(() => {
    const thismonth = parseInt(new Date().getMonth()) + 1;
    const lastmonth = thismonth === 1 ? 12 : thismonth - 1;
    console.log(lastmonth);

    const getBills = async () => {
      // const res = await axios.get("/api/bills");
      const res = await fetch(
        "https://minewhouseholdserver.duckdns.org/api/bills"
      );
      const bills = await res.json();

      var data = [];

      switch (active) {
        case "Katelin":
          data = bills.filter((e) => e.bill.user === "katelin");
          break;
        case "Jaxtyn":
          data = bills.filter((e) => e.bill.user === "jaxtyn");
          break;
        case "MiNew":
          data = bills.filter((e) => e.bill.user === "minew");
          break;
        default:
          data = bills;
          break;
      }

      switch (dateRange) {
        case "LastMonth":
          data = data.filter(
            (e) => parseInt(e.bill.date.split("-")[1]) === lastmonth
          );
          break;
        case "ThisMonth":
          data = data.filter(
            (e) => parseInt(e.bill.date.split("-")[1]) === thismonth
          );
          break;
        default:
          break;
      }

      setBills(data.reverse());
    };

    getBills();
  }, [open, updateBill, active, dateRange]);

  return (
    <>
      <div
        className="title-bar"
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          padding: "0 20px",
          margin: 0,
          backgroundColor: "#dedede",
        }}
      >
        <Link to="/">
          <Button
            variant="text"
            style={{ padding: 0, width: "auto", color: "#333333" }}
          >
            <KeyboardBackspaceIcon />
          </Button>
        </Link>
        <h1>
          <span style={{ fontWeight: 500, fontSize: 23 }}>Index Page</span>
        </h1>
        <Button
          variant="outlined"
          style={{ height: 40, borderRadius: 20, fontSize: 20 }}
          onClick={handleClickOpen}
        >
          +
        </Button>
      </div>
      <section className="inner-container">
        <div className="index-action-container">
          <div className="index-action-inner">
            <ButtonGroup
              variant="contained"
              aria-label="outlined primary button group"
            >
              <Button
                variant={active === "All" ? "outlined" : ""}
                style={{ color: active === "All" ? "#333333" : "#979797" }}
                onClick={() => {
                  handleUserClicked("All");
                }}
              >
                All
              </Button>
              <Button
                variant={active === "Katelin" ? "outlined" : ""}
                style={{ color: active === "Katelin" ? "#333333" : "#979797" }}
                onClick={() => {
                  handleUserClicked("Katelin");
                }}
              >
                Katelin
              </Button>
              <Button
                variant={active === "Jaxtyn" ? "outlined" : ""}
                style={{ color: active === "Jaxtyn" ? "#333333" : "#979797" }}
                onClick={() => {
                  handleUserClicked("Jaxtyn");
                }}
              >
                Jaxtyn
              </Button>
              <Button
                variant={active === "MiNew" ? "outlined" : ""}
                style={{ color: active === "MiNew" ? "#333333" : "#979797" }}
                onClick={() => {
                  handleUserClicked("MiNew");
                }}
              >
                MiNew
              </Button>
            </ButtonGroup>
          </div>
          <div className="index-action-inner">
            <ButtonGroup
              variant="contained"
              aria-label="outlined primary button group"
            >
              <Button
                variant={dateRange === "All" ? "outlined" : ""}
                style={{ color: dateRange === "All" ? "#333333" : "#979797" }}
                onClick={() => {
                  handleDateRangeClicked("All");
                }}
              >
                All
              </Button>
              <Button
                variant={dateRange === "LastMonth" ? "outlined" : ""}
                style={{
                  color: dateRange === "LastMonth" ? "#333333" : "#979797",
                }}
                onClick={() => {
                  handleDateRangeClicked("LastMonth");
                }}
              >
                Last Month
              </Button>
              <Button
                variant={dateRange === "ThisMonth" ? "outlined" : ""}
                style={{
                  color: dateRange === "ThisMonth" ? "#333333" : "#979797",
                }}
                onClick={() => {
                  handleDateRangeClicked("ThisMonth");
                }}
              >
                This Month
              </Button>
            </ButtonGroup>
          </div>
        </div>
        {bills && <Datatable data={bills} setUpdateBill={setUpdateBill} />}
        <AddDialog open={open} handleClose={handleClose} />
      </section>
    </>
  );
}
