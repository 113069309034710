import * as React from "react";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";

export default function SelectUserRadio({ user, setUser }) {
  const handleChange = (event) => {
    setUser(event.target.value);
  };

  return (
    <FormControl>
      <RadioGroup
        row
        aria-labelledby="demo-row-radio-buttons-group-label"
        name="row-radio-buttons-group"
        value={user}
        onChange={handleChange}
      >
        <FormControlLabel value="katelin" control={<Radio />} label="Katelin" />
        <FormControlLabel value="jaxtyn" control={<Radio />} label="Jaxtyn" />
        <FormControlLabel value="minew" control={<Radio />} label="MiNew" />
      </RadioGroup>
    </FormControl>
  );
}
