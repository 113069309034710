import React, { useEffect, useState } from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import CategoryBar from "./CategoryBar";

export default function MonthlySummary({ data }) {
  const [katelinExpectPayment, setKatelinExpectpayment] = useState(0);
  const [jaxtynDebitOutcome, setJaxtynDebitOutcome] = useState(0);
  const [minewDebitOutcome, setMinewDebitOutcome] = useState(0);
  const [katelinIncome, setKatelinIncome] = useState(0);
  const [jaxtynIncome, setJaxtynIncome] = useState(0);
  const [minewIncome, setMinewIncome] = useState(0);

  useEffect(() => {
    const setOutcomes = () => {
      var expectKtc = 0;
      data.forEach((e) => {
        if (e.bill.paymentType === "ktc" && e.bill.inout === "out") {
          expectKtc += parseFloat(e.bill.amount);
        }
      });

      var expectJtd = 0;
      data.forEach((e) => {
        if (e.bill.paymentType === "jtd" && e.bill.inout === "out") {
          expectJtd += parseFloat(e.bill.amount);
        }
      });

      var expectMnd = 0;
      data.forEach((e) => {
        if (e.bill.paymentType === "mnd" && e.bill.inout === "out") {
          expectMnd += parseFloat(e.bill.amount);
        }
      });
      setKatelinExpectpayment(expectKtc);
      setJaxtynDebitOutcome(expectJtd);
      setMinewDebitOutcome(expectMnd);
    };

    const setIncomes = () => {
      var incomeKatelin = 0;
      data.forEach((e) => {
        if (e.bill.user === "katelin" && e.bill.inout === "in") {
          incomeKatelin += parseFloat(e.bill.amount);
        }
      });

      var incomeJaxtyn = 0;
      data.forEach((e) => {
        if (e.bill.user === "jaxtyn" && e.bill.inout === "in") {
          incomeJaxtyn += parseFloat(e.bill.amount);
        }
      });

      var incomeMinew = 0;
      data.forEach((e) => {
        if (e.bill.user === "minew" && e.bill.inout === "in") {
          incomeMinew += parseFloat(e.bill.amount);
        }
      });
      setKatelinIncome(incomeKatelin);
      setJaxtynIncome(incomeJaxtyn);
      setMinewIncome(incomeMinew);
    };

    setOutcomes();
    setIncomes();
  }, [data]);

  return (
    <>
      <Card sx={{ minWidth: 275, marginBottom: 5 }}>
        <CardContent>
          <Typography gutterBottom variant="h5" component="div">
            Outcomes
          </Typography>
        </CardContent>
        <CardContent>
          <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
            Katelin Credit Expected Payment
          </Typography>
          <h3>
            {katelinExpectPayment} <span style={{ fontWeight: 400 }}>CAD</span>
          </h3>
          <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
            Jaxtyn's Debit Outcome
          </Typography>
          <h3>
            {jaxtynDebitOutcome} <span style={{ fontWeight: 400 }}>CAD</span>
          </h3>
          <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
            MiNew's Outcome
          </Typography>
          <h3>
            {minewDebitOutcome} <span style={{ fontWeight: 400 }}>CAD</span>
          </h3>
        </CardContent>
      </Card>
      <Card sx={{ minWidth: 275, marginBottom: 5 }}>
        <CardContent>
          <Typography gutterBottom variant="h5" component="div">
            Incomes
          </Typography>
        </CardContent>
        <CardContent>
          <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
            Katelin
          </Typography>
          <h3>
            {katelinIncome} <span style={{ fontWeight: 400 }}>CAD</span>
          </h3>
          <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
            Jaxtyn's
          </Typography>
          <h3>
            {jaxtynIncome} <span style={{ fontWeight: 400 }}>CAD</span>
          </h3>
          <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
            MiNew's
          </Typography>
          <h3>
            {minewIncome} <span style={{ fontWeight: 400 }}>CAD</span>
          </h3>
        </CardContent>
      </Card>
      <Card sx={{ minWidth: 275, marginBottom: 5 }}>
        <CardContent>
          <Typography gutterBottom variant="h5" component="div">
            Consumption status by category
          </Typography>
        </CardContent>
        <CardContent>
          <CategoryBar data={data} />
        </CardContent>
      </Card>
    </>
  );
}
